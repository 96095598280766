import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";
import { Instagram } from "styled-icons/fa-brands";
import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";
import imgL from "../../assets/image/webp/main-portrait.webp";
import {background, border, color, flexbox, layout, shadow, space, typography} from "styled-system";

const ImgRight = styled.img`
  max-width: 100%;
`;

const InstagramIcon = styled(Instagram)`
  color: #6f6b66;
`;

const InstagramButton = styled.button`
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1.63px;
  text-transform: uppercase;
  padding: 23px 30px;
  box-shadow: 0 32px 54px rgba(3, 3, 3, 0.12);
  border-radius: 50rem;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  transform: perspective(1px) translateZ(0);
  position: relative;
  overflow: hidden;
  border: none;
  outline: none !important;
  white-space: nowrap;
  color: #6f6b66;
  background: white;
  transition: 0.4s ease-out;

  &:hover,
  &:focus,
  &:active {
    transform: translateY(-10px);
    box-shadow: 0 32px 54px rgba(3, 3, 3, 0.14);
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section hero className="position-relative">
        <Container>
          <Row className="align-items-center">
            <Col lg="7" className="mb-5 mb-lg-0">
              <Box>
                <Title variant="hero">
                  Люблю создавать красивые вещи
                </Title>

                <Box mt="52px">
                  <Row>
                    <Col className={"col-md-6 mb-3"}>
                      <Link
                          to="works"
                          spy={true}
                          smooth={true}
                          offset={-50}
                          duration={1000}
                      >
                        <Button arrowRight>Мои работы </Button>
                      </Link>
                    </Col>
                    <Col  className={"col-md-6"}>
                      <a
                          href="https://www.instagram.com/tl_knitting"
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                        <InstagramButton><InstagramIcon size={"20"} className="mr-2"/>Я В Instagram</InstagramButton>
                      </a>
                    </Col>
                  </Row>
                </Box>
              </Box>
            </Col>
            <Col lg="5" md="8" sm="9">
              <div className="text-center text-lg-right position-relative">
                <div className="img-main">
                  <ImgRight src={imgL} alt="" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
