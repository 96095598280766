import React, { useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap";
import Masonry from "react-masonry-component";
import InfiniteScroll from 'react-infinite-scroller';
import { Section, Box, ListNav } from "../../components/Core";
import WorkCard from "../../components/WorkCard";

const Works = () => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [workPage, setWorkPage] = useState(0);

  const [originalItems, setOriginalItems] = useState([]);
  const [categoryItems, setCategoryItems] = useState([]);
  const [activeLink, setActiveLink] = useState("all");

  const fetchData = () => {
    return fetch("https://tlknitting.com/service/categories")
        .then((response) => response.json())
        .then((data) => {
          setCategoryItems(data);
        });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const filterBy = (cat) => {
      setActiveLink(cat);
      setItems([]);
      setOriginalItems([]);
      setWorkPage(0);
      setHasMore(true);
  };

  const masonryOptions = {
    transitionDuration: 1000,
  };

  return (
    <>
      {/* <!-- Works Area --> */}
      <Section className="position-relative">
        <Container>
          <Box mb="2.5rem" ml="-1.75rem">
            <ListNav className="nav">
              <li className="nav-item">
                <a
                  className={`nav-link font-weight-bold text-uppercase ${
                    activeLink === "all" ? "active" : null
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    filterBy("all");
                  }}
                >
                  Все работы
                </a>
              </li>
              {categoryItems.map((item, index) => (
                  <li className="nav-item" key={index}>
                    <a
                        className={`nav-link font-weight-bold text-uppercase ${
                            activeLink === item.code ? "active" : null
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          filterBy(item.code);
                        }}
                    >
                      {item.name}
                    </a>
                  </li>
              ))}
            </ListNav>
          </Box>
        </Container>

        <Container fluid>
          <InfiniteScroll
              pageStart={0}
              loadMore={()=> {
                if (isLoading) {
                  return;
                }
                let currentPage = workPage + 1;
                setIsLoading(true);
                setWorkPage(currentPage);
                  fetch("https://tlknitting.com/service/works/"+ activeLink + '/' + currentPage)
                    .then((response) => response.json())
                    .then((data) => {
                      if (!data.length) {
                          setHasMore(false);
                          setIsLoading(false);
                          return;
                      }
                      setOriginalItems(originalItems.concat(data));
                      setItems(items.concat(data));
                      setIsLoading(false);
                    });
              }}
              hasMore={hasMore}
          >
              <Masonry
                  options={masonryOptions}
                  className={"masonry-grid row"} // default ''
              >
                  {items.map((item, index) => (
                      <Col lg="3" md="4" sm="6" key={index} className="filtr-item">
                          <WorkCard workItem={item} mb="30px" link={item.url}/>
                      </Col>
                  ))}
              </Masonry>
          </InfiniteScroll>
        </Container>
      </Section>
    </>
  );
};

export default Works;
